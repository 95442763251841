import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  providers: [HttpClientModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'soma.volume.delivered.declaration';

  ngOnInit(): void {
    this.setTheme();
  }

  setTheme() {
    document.documentElement.style.setProperty('--color-primary', '#ffa125');
    document.documentElement.style.setProperty('--color-secondary', '#fff5e9');
    document.documentElement.style.setProperty('--color-tertiary', '#DD8613');
    document.documentElement.style.setProperty(
      '--color-button-text',
      '#001633'
    );
    document.documentElement.style.setProperty(
      '--icon-url',
      'https://somastoragedev.blob.core.windows.net/multi-tenant-image/tenant-1-icon.svg'
    );
  }
}
