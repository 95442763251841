import { Routes } from '@angular/router';
import { InitPageComponent } from './pages/init-page/init-page.component';

export const routes: Routes = [
    { path: '', redirectTo: 'init-page', pathMatch: 'full' },
    {
        path: 'init-page',
        component: InitPageComponent
    },
    {
        path: 'select-month/:personId/:name',
        loadComponent: () =>
            import('../app/pages/select-month-page/select-month-page.component')
            .then(m => m.SelectMonthPageComponent)        
    },
    { path: '**', redirectTo: 'init-page', pathMatch: 'full' },
];
