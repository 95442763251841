<div class="container-login white-bg">
  <div class="card-form row start h-90vh">
    <p-card>
      <img class="logo" [src]="icon" />
      <form
        [formGroup]="legalPersonForm"
        autocomplete="on"
        class="needs-validation"
        (ngSubmit)="getMonthList()"
      >
        <div class="needs-validation">
          <label
            for="documentNumber"
            class="f-neutral-3 fw-700 f-14px pt-18px lh-24px"
            >CPF/CNPJ</label
          >
          <input
            formControlName="documentNumber"
            type="tel"
            placeholder="Digite o CPF ou CNPJ"
            required="true"
            mask="000.000.000-00||00.000.000/0000-00"
            [dropSpecialCharacters]="true"
            class="default-field form-control"
            [ngClass]="
              (legalPersonForm.get('documentNumber')?.dirty ||
                legalPersonForm.get('documentNumber')?.touched) &&
              legalPersonForm.get('documentNumber')?.errors
                ? 'is-invalid'
                : ''
            "
          />
          <div class="invalid-feedback">
            {{
              legalPersonForm.get("documentNumber")?.hasError("required")
                ? "Insira o CPF ou CNPJ"
                : "CPF ou CNPJ inválido"
            }}
          </div>
        </div>
        <div class="row pt-24px pb-40px">
          <div class="col-12">
            <button
              class="btn btn-default-primary"
              type="submit"
              [disabled]="loading || legalPersonForm.invalid"
            >
              @if(loading){
              <span
                class="spinner-border spinner-border-sm"
                aria-hidden="true"
              ></span>

              <span role="status"> Carregando...</span>
              }@else {
              <span role="status">Avançar</span>
              }
            </button>
          </div>
        </div>
      </form>
    </p-card>
  </div>
</div>
