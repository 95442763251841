import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  NgxMaskDirective,
  NgxMaskPipe,
  NgxMaskService,
  provideNgxMask,
} from 'ngx-mask';
import { CardModule } from 'primeng/card';
import { PeopleService } from '../../shared/services/people.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-init-page',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
  ],
  providers: [provideNgxMask()],
  templateUrl: './init-page.component.html',
  styleUrl: './init-page.component.scss',
})
export class InitPageComponent {
  constructor(
    private router: Router,
    private service: PeopleService,
    private fb: FormBuilder,
    private toast: ToastrService
  ) {}
  icon: string =
    'https://somastoragedev.blob.core.windows.net/multi-tenant-image/tenant-1-icon.svg';
  loading: boolean = false;
  legalPersonForm = this.fb.group({
    documentNumber: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(11),
        Validators.maxLength(14),
      ]),
    ],
  });

  accountId: string | null = null;

  getMonthList() {
    this.loading = true;
    if (this.legalPersonForm.valid) {
      this.service
        .checkDocumentExists(
          this.legalPersonForm.controls.documentNumber.value!
        )
        .subscribe({
          next: (res) => {
            if (res)
              this.router.navigateByUrl(`select-month/${res.id}/${res.name}`);
            this.loading = false;
          },

          error: (err) => {
            this.toast.error('Usuário e/ou senha inválidos.');
            this.loading = false;
          },
        });
    }
  }
}
